/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "gatsby";

export default function footer() {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-4 col-md-2">© {new Date().getFullYear()} Lendmarx, LLC</div>
          <ul className="homepage-footer-links col-8 col-md-8 col-lg-9 text-right">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://healthy-quotes.com/tcpa"
                rel="noreferrer"
              >
                TCPA Partners
              </a>
            </li>
          </ul>

          <div className="col-12 mt-4" style={{fontSize: "12px"}} >
            {/* Wilkinson */}
          </div>
        </div>
      </div>
    </footer>
  );
}
